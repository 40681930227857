<template>
    <div 
        class="w-full h-full flex flex-col"
        v-if="activeSection && !loading">
        
        <div 
            class="
                flex-none flex items-center 
                flex-col
                md:h-32 md:py-8 md:px-16 md:flex-row md:bg-transparent 
            ">
            
            <div 
                class="
                    flex-grow flex items-center 
                    bg-clay h-12 w-full justify-center
                    md:bg-transparent md:h-auto md:w-auto md:mr-8 md:justify-start
                ">
                
                <h1 class="text-white md:text-black">
                    {{ offerEdit.offerId ? $t('offers.editOffer') : $t('offers.newOffer') }}
                </h1>
            </div>
            
            <div 
                class="
                    px-4
                    bg-white w-full 
                    md:bg-transparent md:w-auto
                ">
                
                <Tabs 
                    :tabs="sections" 
                    :active="activeSection" 
                    @click="setActiveSection($event)"
                    class="md:flex-grow md:justify-end my-3 md:my-0"
                />
            </div>
        </div>
        <div 
            v-if="allProducts && allCustomers"
            class="flex-grow w-full h-full flex overflow-hidden ">
            
            <!-- customer -->
            <section 
                v-if="activeSection == 'customer'" 
                class="
                    h-full w-full overflow-y-auto
                    p-4
                    md:px-16
                ">
                
                <div 
                    v-if="offerEdit.customerId && customer"
                    class="w-full">
                    
                    <div class="panel max-w-3xl mx-auto">
                        <h2 class="text-black leading-none mb-8">
                            <span v-if="customer.isCompany">{{customer.name}}</span>
                            <span v-else>{{contact.name }}</span>
                        </h2>
                        
                        <div 
                            class="
                                grid 
                                grid-cols-1 gap-0
                                md:grid-cols-2 md:gap-8
                            ">
                            
                            <div class="">
                                <div 
                                    v-if="customer.street"
                                    class="mb-8">
                                    
                                    <h3 class="text-black mb-0">{{$t('customers.address')}}</h3>
                                    {{customer.postcode}} {{customer.city}}<br>
                                    {{customer.street}}<br>
                                    {{customer.country}}<br>
                                </div>
                                <div 
                                    v-else
                                    class="mt-2 opacity-75 w-64">
                                    
                                    {{$t('customers.noAddress')}}
                                </div>
                                
                                <div 
                                    v-if="customer.website"
                                    class="mb-8">
                                    
                                    <h3 class="text-black mb-0">{{$t('customers.website')}}</h3>
                                    {{customer.website}}
                                </div>
                                
                                <div 
                                    v-if="customer.organizationNumber"
                                    class="mb-8">
                                    
                                    <h3 class="text-black mb-0">{{$t('customers.organizationNumber')}}</h3>
                                    {{customer.organizationNumber}}
                                </div>
                            </div>
                            <div class="">
                                <h3 class="text-black">
                                     <!-- mt-0 mb-0 md:mt-8 md:mb-6 -->
                                    {{$t('customers.contactPersons')}}
                                </h3>
                                <div 
                                    v-for="contact in customer.contacts"
                                    :key="contact.contactId"
                                    class="
                                        rounded relative mb-4 border border-gray-200
                                        p-4 
                                        md:p-8 
                                    "
                                    :class="contact.contactId == offerEdit.contactId ? 'bg-gray-100' : ''"
                                    @click="offerEdit.contactId = contact.contactId">
                                    
                                    <input 
                                        name="primaryContact"
                                        v-model="offerEdit.contactId"
                                        :value="contact.contactId"
                                        type="radio"
                                        class="
                                            absolute top-0 right-0
                                            m-4
                                            md:m-8
                                        "
                                        title="Use this contact"
                                    />
                                    
                                    <div 
                                        v-if="contact.name" 
                                        class="mb-4">
                                        {{contact.name}}
                                    </div>
                                    
                                    <div 
                                        v-if="contact.title" 
                                        class="flex">
                                        <div class="w-16">
                                            {{$t('customers.jobTitle')}}
                                        </div>
                                        {{contact.title}}
                                    </div>
                                    
                                    <div 
                                        v-if="contact.phone" 
                                        class="flex">
                                        <div class="w-16">
                                            {{$t('customers.phoneNumber')}}
                                        </div>
                                        {{contact.phone}}
                                    </div>
                                    
                                    <div 
                                        v-if="contact.email" 
                                        class="flex">
                                        <div class="w-16">
                                            {{$t('customers.email')}}
                                        </div> 
                                        <a 
                                            class="link"
                                            @click.stop
                                            :href="`mailto:${contact.email}`">
                                            {{contact.email}}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="mt-4 text-center md:text-left">
                            <a 
                                class="link mr-4"
                                @click.prevent="customerEdit = customer">
                                
                                <i class="fas fa-edit mr-2" />
                                {{$t('customers.editCustomer')}}
                            </a>
                            
                            <a 
                                class="link"
                                @click.prevent="removeCustomer()">
                                
                                <i class="fas fa-minus-circle mr-2" />
                                {{$t('customers.detachCustomer')}}
                            </a>
                        </div>
                        
                    </div>
                    
                </div>
                <div 
                    v-else
                    class="w-full h-full">
                    
                    <transition-group 
                        appear
                        tag="div"
                        name="slide-up" 
                        class="grid gap-4">
                        
                        <div 
                            v-for="customer in allCustomers"
                            :key="customer.customerId"
                            class="slide-up-item">
                            
                            <CustomerCard 
                                v-if="isMobile"
                                @edit="addCustomer(customer)"
                                :customer="customer"
                            />
                            <CustomerRow 
                                v-else
                                @edit="addCustomer(customer)"
                                :customer="customer"
                            />
                        </div>
                    </transition-group>
                </div>
                <div class="flex justify-center">
                    <button 
                        v-if="offerEdit.customerId"
                        class="button submit mt-8"
                        @click="activeSection = 'products'">
                        
                        {{$t('offers.nextStep')}}
                        <i class="fas fa-chevron-right mx-2" />
                    </button>
                </div>
                
                <div 
                    v-if="customerEdit"
                    class="
                        absolute inset-0 flex
                        mb-16 
                        md:mb-0
                    ">
                    
                    <div 
                        @click="customerEdit = null"
                        class="flex-grow bg-black opacity-25 ">
                        
                    </div>
                    
                    <transition name="fade">
                        <CustomerEdit 
                            :customer="customerEdit" 
                            @close="customerEdit = null"
                            class="
                                absolute bg-white right-0 overflow-hidden
                                md:rounded-l-lg 
                                md:w-150
                            "
                        />
                    </transition>
                </div>
                
            </section>
            
            <!-- products -->
            <section 
                v-else-if="activeSection == 'products'"
                class="
                    h-full w-full overflow-y-auto
                    p-4
                    md:px-16
                ">
                
                <!-- 
                <h2>
                    <span v-if="customer.isCompany">Is company. Do not show VAT</span>
                    <span v-else>Personal. Show VAT</span>
                </h2>
                -->
                
                <div 
                    v-if="offerEdit.products.length && allProducts"
                    class="overflow-hidden grid grid-cols-1 gap-4 max-w-xl mx-auto">
                    
                    <div 
                        v-for="(product,index) in offerProducts"
                        :key="'inOffer_'+product.productId"
                        class="slide-up-item ">
                        
                        <!-- <pre class="bg-yellow-400">{{product.name}}</pre> -->
                        
                        <OfferProductCard 
                            :originalProduct="allProducts.find( p => p.productId == product.productId)"
                            :offerProduct="offerEdit.products.find( p => p.productId == product.productId)"
                            :offer="offerEdit">
                            
                            <header slot="contextMenu">
                                <i 
                                    class="fas fa-ellipsis-v w-6 h-6 flex items-center justify-center absolute top-0 right-0 m-6 text-gray-400 hover:text-cyan"
                                    @click="showProductContextMenu = product.productId"
                                />
                                
                                <div 
                                    class=" fixed inset-0"
                                    v-if="showProductContextMenu == product.productId"
                                    @click="showProductContextMenu = null">
                                    
                                </div>
                                <div 
                                    v-if="showProductContextMenu == product.productId"
                                    class="bg-white absolute right-0 top-0 m-3 rounded-md flex flex-col border"
                                    style="box-shadow: 0 0px 10px rgba(0,0,0,.1); z-index: 1;">
                                    
                                    <a 
                                        v-if="offerEdit.products.length > 1"
                                        class="link px-4 py-2 "
                                        :class="index == 0 ? 'opacity-25' : null"
                                        @click="showProductContextMenu = null; moveProduct(product,index,'up')">
                                        
                                        <i class="fas fa-chevron-up mr-1 text-center" />
                                        {{$t('general.move')}}
                                    </a>
                                    <a 
                                        v-if="offerEdit.products.length > 1"
                                        class="link px-4 py-2 border-t"
                                        :class="index+1 == offerEdit.products.length ? 'opacity-25' : null"
                                        @click="showProductContextMenu = null; moveProduct(product,index,'down')">
                                        
                                        <i class="fas fa-chevron-down mr-1 text-center" />
                                        {{$t('general.move')}}
                                    </a>
                                    <a 
                                        class="link px-4 py-2 border-t"
                                        @click.prevent="showProductContextMenu = null; productEdit = allProducts.find( p => p.productId == product.productId)">
                                        
                                        <i class="fas fa-edit mr-1 text-center" />
                                        {{$t('general.edit')}}
                                    </a>
                                    <a 
                                        class="link px-4 py-2 border-t"
                                        @click.prevent="showProductContextMenu = null; removeProduct(product)">
                                        
                                        <i class="fas fa-minus-circle mr-1 text-center" />
                                        {{$t('general.remove')}}
                                    </a>
                                </div>
                            </header>
                            
                        </OfferProductCard>
                    </div>
                    
                    
                    <div class="panel">
                        <form 
                            class="
                                grid 
                                gap-4
                                grid-cols-3
                            ">
                            
                            <div>
                                <label class="mt-0">
                                    {{$t('offers.total')}} 
                                    
                                    <span v-if="offerEdit.show.vatCalculation">
                                        {{$t('products.excludedVat')}} 
                                    </span>
                                    <span v-else>
                                        {{account.locale.currency}}
                                    </span>
                                </label>
                                <input 
                                    type="text"
                                    class="text-center w-full"
                                    style="margin-bottom: 0"
                                    :value="currencyFormat( priceTotalTemp, false )"
                                    disabled
                                    readonly
                                />
                            </div>
                            <div>
                                <label class="mt-0">
                                    {{$t('offers.discount')}}
                                </label>
                                <input 
                                    type="number"
                                    class="text-center w-full"
                                    style="margin-bottom: 0"
                                    :value="offerEdit.priceTotalAdjustDiscountRounded"
                                    @change="priceTotalAdjustDiscountChange"
                                />
                            </div>
                            <div>
                                <label class="mt-0">
                                    {{$t('offers.adjustedTotal')}}
                                    
                                    <span v-if="offerEdit.show.vatCalculation">
                                        {{$t('products.excludedVat')}} 
                                    </span>
                                    <span v-else>
                                        {{account.locale.currency}}
                                    </span>
                                </label>
                                <input 
                                    type="number"
                                    class="text-center w-full"
                                    style="margin-bottom: 0"
                                    :value="offerEdit.priceTotalAdjustPrice"
                                    @change="priceTotalAdjustPriceChange"
                                />
                                <!-- @focus="$event.target.select()" -->
                            </div>
                        </form>
                        
                        <form 
                            class="
                                grid 
                                gap-4
                                grid-cols-3
                                mt-4
                            ">
                             
                            <div 
                                v-if="offerEdit.show.vatCalculation" 
                                class="col-start-2">
                                 
                                <label class="mt-0">
                                    <!-- {{$t('offers.total')}}  -->
                                    {{$t('products.valueAddedTax')}} 
                                    <!-- {{account.locale.currency}} -->
                                </label>
                                <input 
                                    type="text"
                                    class="text-center w-full"
                                    style="margin-bottom: 0"
                                    :value="currencyFormat( totalVatAmount, false )"
                                    disabled
                                    readonly
                                />
                            </div>
                            <div 
                                v-if="offerEdit.show.vatCalculation" 
                                class="">
                                 
                                <label class="mt-0">
                                {{$t('offers.total')}} {{$t('products.includedVat')}} 

                                </label>
                                <input 
                                    type="text"
                                    class="text-center w-full"
                                    style="margin-bottom: 0"
                                    :value="currencyFormat( priceTotalIncludedVat, false )"
                                    disabled
                                    readonly
                                />
                            </div>
                        </form>
                        
                    </div>
                    
                    <div class="flex flex-col items-center">
                        <div class="text-center my-4">
                            <label class="m-0">
                                <input 
                                    class="mr-1"
                                    type="checkbox" 
                                    v-model="offerEdit.show.vatCalculation"
                                />
                                
                                {{$t('offers.useVat')}}
                            </label>
                        </div>
                        
                        <button 
                            class="button submit mt-4 float-right"
                            @click="activeSection = 'design'">
                            
                            {{$t('offers.nextStep')}} <i class="fas fa-chevron-right mx-2" />
                        </button>
                    </div>
                </div>
                
                <div v-if="showProductsList || !offerEdit.products.length">
                    <div 
                        v-if="offerEdit.products.length"
                        @click.prevent="showProductsList = false"
                        :title="$t('general.close')"
                        class="mx-auto w-24">
                        
                        <i class="fas fa-times text-lg bg-white inline-block w-10 h-10 rounded-full flex items-center justify-center mx-auto mb-4 mt-8" />
                    </div>
                    
                    <transition-group 
                        appear
                        name="slide-up" 
                        tag="div"
                        class="
                            grid 
                            grid-cols-1 
                            lg:grid-cols-2 
                            xl:grid-cols-3 
                            gap-4
                            md:gap-16
                        ">
                        
                        <ProductCard 
                            v-for="(product) in allProductsWithoutOfferProducts"
                            :key="'notInOffer_'+product.productId"
                            :product="product"
                            @edit="addProduct(product)"
                            class="slide-up-item"
                        />
                    </transition-group>
                </div>
                <div v-else>
                    <div 
                        @click.prevent="showProductsList = true"
                        :title="$t('offers.addProduct')"
                        class="mx-auto w-24">
                        
                        <i class="fas fa-plus text-lg bg-white inline-block w-10 h-10 rounded-full flex items-center justify-center mx-auto mb-4 mt-8" />
                    </div>
                </div>
                
                <div 
                    v-if="productEdit"
                    class="
                        absolute inset-0 flex
                        mb-16 
                        md:mb-0
                    ">
                    
                    <div 
                        @click="productEdit = null"
                        class="flex-grow bg-black opacity-25 ">
                        
                    </div>
                    <transition name="fade">
                        <ProductEdit 
                            :product="productEdit" 
                            @close="productEdit = null"
                            class="
                                absolute bg-white right-0 overflow-hidden
                                md:rounded-l-lg 
                                md:w-150
                            "
                        />
                    </transition>
                </div>
                
            </section>
            
            <!-- design -->
            <section 
                v-if="activeSection == 'design'"
                class="h-full w-full flex">
                
                <div 
                    class="
                        flex-none overflow-y-auto
                        w-full
                        md:w-100 md:px-12 
                    "
                    :class="!isMobile || showDesignCustomize ? 'block' : 'hidden'">
                    
                    <div 
                        v-if="showDesignCustomize && isMobile"
                        class="bg-gray-100 p-4 flex justify-center items-center">
                        
                        <button 
                            class="button transparent mr-2"
                            @click="showDesignCustomize = !showDesignCustomize">
                             
                            <i class="fas fa-times " />
                        </button>
                        
                        <h2 class="text-black m-0 flex-grow">Design Customize</h2>
                    </div>
                    
                    <form class="p-4">
                        <div class="hidden">
                            <label 
                                for="designs"
                                class="mt-0">
                                
                                {{$t('offers.designTemplate')}}
                            </label>
                            
                            <div class="flex -mb-4">
                                <select 
                                    id="designs"
                                    class="flex-grow" 
                                    v-model="offerEdit.designId">
                                        
                                    <option 
                                        v-for="design in designs"
                                        :value="design.designId"
                                        :key="design.designId">
                                        {{design.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        
                        <label class="mt-0 mb-4 inline-block w-auto">
                            <input 
                                class="mr-1"
                                type="checkbox" 
                                :checked="offerEdit.expireAt ? true : false"
                                @input="toggleOfferExpireAt()"
                            />
                            
                            {{$t('offers.setExpirationDate')}}
                        </label>
                        <div v-if="offerEdit.expireAt">
                            <DatePicker 
                                v-model="offerEdit.expireAt" 
                                value-type="timestamp"
                                type="datetime" 
                                placeholder="Expire at date / time"
                                :lang="{formatLocale: { firstDayOfWeek: 1}}"
                            />
                            <!-- :disabled-date="notBeforeToday"
                            :disabled-time="notBeforeTodayTwelveOClock" -->
                        </div>
                        
                        
                        
                        <label class="m-0">
                            <input 
                                class="mr-1"
                                type="checkbox" 
                                v-model="offerEdit.showCustomer"
                            />
                            
                            {{$t('offers.showAttentionCustomer')}}
                        </label>
                        
                        
                        <label for="heading">
                            {{$t('offers.offerHeading')}}
                            <!-- <a 
                                class="link ml-2 float-right"
                                @click="saveSnippet('heading', offerEdit.heading )"
                                title="Save Snippet">
                                
                                <i class="fas fa-save" />
                            </a> -->
                            <!-- <a 
                                class="link ml-2 text-sm"
                                @click="showSnippets('heading')"
                                :title="$t('offers.browseSnippets')">
                                
                                <i class="fas fa-file-import" />
                            </a> -->
                        </label>
                        <!-- <Modal 
                            v-if="snippetModal == 'heading'"
                            @close="snippetModal = null">
                            
                            <header slot="header" class="mb-8">Heading Snippets</header>
                            
                            <div 
                                v-for="snippet in snippets.heading.no"
                                :key="snippet"
                                @click="
                                    offerEdit.heading = snippet;
                                    snippetModal = null;"
                                class="link italic my-4 py-4 border-t border-dashed">
                                
                                {{snippet}}
                            </div>
                        </Modal> -->
                        <input 
                            type="text"
                            id="heading"
                            v-model="offerEdit.heading"
                        />
                        
                        <label for="text">
                            {{$t('offers.offerText')}}
                            
                            <!-- <a 
                                class="link ml-2 float-right"
                                @click="saveSnippet('heading', offerEdit.text )"
                                title="Save Snippet">
                                
                                <i class="fas fa-save" />
                            </a> -->
                            <!-- <a 
                                class="link ml-2 text-sm"
                                @click="showSnippets('text')"
                                title="Browse Snippets">
                                
                                <i class="fas fa-file-import" />
                            </a> -->
                        </label>
                        <!-- <Modal 
                            v-if="snippetModal == 'text'"
                            @close="snippetModal = null">
                            
                            <header slot="header" class="mb-8">Text Snippets</header>
                            
                            <div 
                                v-for="snippet in snippets.text.no"
                                :key="snippet"
                                @click="
                                    offerEdit.text = snippet;
                                    snippetModal = null;"
                                class="link italic my-4 py-4 border-t border-dashed">
                                
                                {{snippet}}
                            </div>
                        </Modal> -->
                        <textarea 
                            id="text"
                            v-model="offerEdit.text"
                            class="h-40"
                        />
                        
                        
                        <legend>{{$t('offers.offerSender')}}</legend>
                        
                        <InputSingleline 
                            :label="$t('settings.users.firstname')"
                            v-model="offerEdit.owner.firstname"
                            :required="false"
                            :showRequiredWarning="false"
                            class="w-full"
                        />
                        
                        <InputSingleline 
                            :label="$t('settings.users.lastname')"
                            v-model="offerEdit.owner.lastname"
                            :required="false"
                            :showRequiredWarning="false"
                            class="w-full"
                        />
                        
                        <InputSingleline 
                            :label="$t('settings.users.title')"
                            v-model="offerEdit.owner.title"
                            :required="false"
                            :showRequiredWarning="false"
                            class="w-full"
                        />
                        
                        <InputSingleline 
                            :label="$t('settings.users.phonenumber')"
                            v-model="offerEdit.owner.phonenumber"
                            :required="false"
                            :showRequiredWarning="false"
                            class="w-full"
                        />
                        
                        <InputSingleline 
                            :label="$t('settings.users.email')"
                            v-model="offerEdit.owner.email"
                            :required="false"
                            :showRequiredWarning="false"
                            class="w-full"
                        />
                        
                        
                        
                        <label class="m-0">
                            <input 
                                class="mr-1"
                                type="checkbox" 
                                v-model="offerEdit.show.owner"
                            />
                            {{$t('offers.showOfferSender')}}
                        </label>
                        
                        
                        
                        
                        
                        
                        
                        
                        <label class="m-0">
                            <input 
                                class="mr-1"
                                type="checkbox" 
                                v-model="offerEdit.show.product.priceFormated"
                            />
                            {{$t('offers.showProductPriceFormated')}}
                        </label>
                        
                        <label class="m-0">
                            <input 
                                class="mr-1"
                                type="checkbox" 
                                v-model="offerEdit.show.product.qty"
                            />
                            {{$t('offers.showProductQty')}}
                        </label>
                        
                        <label class="m-0">
                            <input 
                                class="mr-1"
                                type="checkbox" 
                                v-model="offerEdit.show.product.discount"
                            />
                            {{$t('offers.showProductDiscount')}}
                        </label>
                        
                        <label class="m-0">
                            <input 
                                class="mr-1"
                                type="checkbox" 
                                v-model="offerEdit.show.product.priceQuantityFormated"
                            />
                            {{$t('offers.showProductPriceQuantityFormated')}}
                        </label>
                        
                        <label class="m-0">
                            <input 
                                class="mr-1"
                                type="checkbox" 
                                v-model="offerEdit.show.priceTotalAdjustDiscount"
                            />
                            {{$t('offers.showPriceTotalAdjustDiscount')}}
                        </label>
                        
                        <label for="acceptButton">
                            {{$t('offers.acceptButton')}}
                            <!-- <a 
                                class="link ml-2 text-sm"
                                @click="showSnippets('acceptButton')"
                                title="Browse Snippets">
                                
                                <i class="fas fa-file-import" />
                            </a> -->
                        </label>
                        <!-- <Modal 
                            v-if="snippetModal == 'acceptButton'"
                            @close="snippetModal = null">
                            
                            <header slot="header" class="mb-8">Accept Button Snippets</header>
                            
                            <div 
                                v-for="snippet in snippets.acceptButton.no"
                                :key="snippet"
                                @click="
                                    offerEdit.acceptButton = snippet;
                                    snippetModal = null;"
                                class="link italic my-4 py-4 border-t border-dashed">
                                
                                {{snippet}}
                            </div>
                        </Modal> -->
                        
                        <input 
                            type="text"
                            id="acceptButton"
                            v-model="offerEdit.acceptButton"
                        />
                        
                        <label for="declineText">
                            {{$t('offers.declineButton')}}
                            <!-- <a 
                                class="link ml-2 text-sm"
                                @click="showSnippets('declineText')"
                                title="Browse Snippets">
                                
                                <i class="fas fa-file-import" />
                            </a> -->
                        </label>
                        <!-- <Modal 
                            v-if="snippetModal == 'declineText'"
                            @close="snippetModal = null">
                            
                            <header slot="header" class="mb-8">Decline Text Snippets</header>
                            
                            <div 
                                v-for="snippet in snippets.declineText.no"
                                :key="snippet"
                                @click="
                                    offerEdit.declineText = snippet;
                                    snippetModal = null;"
                                class="link italic my-4 py-4 border-t border-dashed">
                                
                                {{snippet}}
                            </div>
                        </Modal> -->
                        <input 
                            type="text"
                            id="declineText"
                            v-model="offerEdit.declineText"
                        />
                        
                        <div class="hidden">
                            <label for="notes">{{$t('offers.notesHeading')}}</label>
                            <textarea 
                                id="notes"
                                v-model="offerEdit.notes"
                            />
                        </div>
                        
                        <label>{{$t('general.attachments')}}</label>
                        
                        <div
                            v-if="offerEdit.attachments.length"
                            class="mb-4 -mx-4">
                            
                            <div 
                                v-for="attachment in offerEdit.attachments"
                                :key="attachment.fileId"
                                class="mx-4 mb-2 p-4 bg-gray-100 flex items-center">
                                
                                <div class="capitalize truncate flex-grow">{{attachment.name}}</div>
                                <div class="flex-none ml-4">{{humanFileSize(attachment.size)}}</div>
                                
                                <div class="flex-none ml-4">
                                    <i 
                                        class="fas fa-trash text-sm w-8 h-8 flex items-center justify-center mr-1"
                                        :title="$t('general.remove')"
                                        @click="removeAttachment(attachment)"
                                    />
                                </div>
                            </div>
                        </div>
                        
                        <InputUpload
                            :name="'attachment'"
                            :label="$t('general.uploadFile')"
                            :multiple="true"
                            @input="onAttachmentAdded($event)"
                            :classes="{
                                uploadFrame: 'bg-white'
                            }"
                        />
                        
                    </form>
                </div>
                <div 
                    class="
                        flex-grow overflow-hidden flex flex-col 
                        md:py-0
                    ">
                    
                    <transition 
                        v-if="!contact || !customer || !offerEdit.products.length"
                        name="pop"
                        appear>
                        
                        <div class="h-full w-full flex items-center justify-center pointer-events-none">
                            <div class="bg-white rounded-full p-12 text-center w-100 h-100 pointer-events-auto">
                                <img 
                                    src="/assets/illustrations/undraw_empty_xct9.svg" 
                                    class="object-contain mb-8 w-48 h-48 mx-auto"
                                    alt="No records illustrations"
                                />
                                
                                <h2 class="text-black">
                                    {{$t('offers.notEnoughOfferDetails')}}
                                </h2>
                                
                                <div v-if="!contact">
                                    {{$t('offers.theOfferIsMissing')}} <a class="link" @click="activeSection = 'customer'">{{$t('offers.aContactPerson')}}</a>
                                </div>
                                <div v-else-if="!customer">
                                    {{$t('offers.theOfferIsMissing')}} <a class="link" @click="activeSection = 'customer'">{{$t('offers.aCustomer')}}</a>
                                </div>
                                <div v-else-if="!offerEdit.products.length">
                                    {{$t('offers.theOfferIsMissing')}} <a class="link" @click="activeSection = 'products'">{{$t('offers.atLeastOneProduct')}}</a>
                                </div>
                            </div>
                        </div>
                    </transition>
                    
                    <Design
                        v-else
                        class="flex-grow overflow-auto "
                        :offer="this"
                    />
                    
                    
                    <!-- :priceTotalFormated="priceTotalFormated"
                    :priceTotalIncludedVatFormated="priceTotalIncludedVatFormated" -->
                    <!-- :priceTotalAdjustDiscount="priceTotalAdjustDiscount" -->
                    
                    <div class="flex-none py-4 flex justify-center">
                        <button 
                            v-if="isMobile"
                            class="link mr-4"
                            @click="showDesignCustomize = !showDesignCustomize">
                            
                            <span v-if="!showDesignCustomize">
                                <i class="fas fa-edit mx-2" /> 
                                {{$t('offers.customize')}}
                            </span>
                            <!-- <span v-else>
                                <i class="fas fa-times mx-2" /> 
                                Close
                            </span> -->
                        </button>
                        
                        <div class="flex-none flex items-center justify-center text-gray-400  rounded-md px-2">
                            <i 
                                @click="previewWidth = 'mobile'"
                                class="fas fa-mobile-alt w-6 h-6 text-lg flex items-center justify-center cursor-pointer mx-1" 
                                :class="previewWidth == 'mobile' ? 'text-cyan' : ''"
                                :title="$t('offers.previewMobileDesign')"
                            />
                            <i 
                                @click="previewWidth = 'tablet'"
                                class="fas fa-tablet-alt w-6 h-6 text-lg flex items-center justify-center cursor-pointer mx-1" 
                                :class="previewWidth == 'tablet' ? 'text-cyan' : ''"
                                :title="$t('offers.previewTabletDesign')"
                            />
                            <i 
                                @click="previewWidth = 'laptop'"
                                class="fas fa-desktop w-6 h-6 text-lg flex items-center justify-center cursor-pointer mx-1" 
                                :class="previewWidth == 'laptop' ? 'text-cyan' : ''"
                                :title="$t('offers.previewLaptopDesign')"
                            />
                        </div>
                        
                        <button 
                            class="button submit mx-4"
                            @click="activeSection = 'send'">
                            
                            {{$t('offers.nextStep')}} <i class="fas fa-chevron-right mx-2" />
                        </button>
                    </div>
                </div>
            </section>
            
            <!-- send -->
            <section 
                v-if="activeSection == 'send'"
                class="h-full w-full overflow-y-auto px-4">
                
                <transition 
                    v-if="!contact || !customer || !offerEdit.products.length"
                    name="pop"
                    appear>
                    
                    <div class="h-full w-full flex items-center justify-center pointer-events-none">
                        <div class="bg-white rounded-full p-12 text-center w-100 h-100 pointer-events-auto">
                            <img 
                                src="/assets/illustrations/undraw_empty_xct9.svg" 
                                class="object-contain mb-8 w-48 h-48 mx-auto"
                                alt="No records illustrations"
                            />
                            
                            <h2 class="text-black">
                                {{$t('offers.notEnoughOfferDetails')}}
                            </h2>
                            
                            <div v-if="!contact">
                                {{$t('offers.theOfferIsMissing')}} <a class="link" @click="activeSection = 'customer'">{{$t('offers.aContactPerson')}}</a>
                            </div>
                            <div v-else-if="!customer">
                                {{$t('offers.theOfferIsMissing')}} <a class="link" @click="activeSection = 'customer'">{{$t('offers.aCustomer')}}</a>
                            </div>
                            <div v-else-if="!offerEdit.products.length">
                                {{$t('offers.theOfferIsMissing')}} <a class="link" @click="activeSection = 'products'">{{$t('offers.atLeastOneProduct')}}</a>
                            </div>
                        </div>
                    </div>
                </transition>
                <div 
                    v-else
                    class="mb-8">
                    
                    <div class="mx-auto max-w-xl">
                        
                        <div class="text-center my-8">
                            <div v-if="!offerEdit.offerId || offerEdit.status == 'draft'">
                                <h1>{{$t('offers.sendNow')}}</h1>
                                <p>{{$t('offers.sendNowText')}}</p>
                            </div>
                            <div v-else>
                                <h1>{{$t('offers.resendOrSave')}}</h1>
                                <p>{{$t('offers.resendOrSaveText')}}</p>
                            </div>
                            
                            <form @submit.prevent>
                                <select 
                                    v-if="!offerEdit.offerId || offerEdit.status == 'draft'"
                                    v-model="offerEdit.sendMethod"
                                    id="sendMethod">
                                    
                                    <option 
                                        v-for="method in sendMethods.filter(m => m.alias != 'save')"
                                        :key="method.alias"
                                        :value="method.alias ? method : null">
                                        {{method.name}}
                                    </option>
                                </select>
                                <select 
                                    v-else
                                    v-model="offerEdit.sendMethod"
                                    id="sendMethod">
                                    
                                    <option 
                                        v-for="method in sendMethods.filter(m => m.alias)"
                                        :key="method.alias"
                                        :value="method.alias ? method : null">
                                        {{method.name}}
                                    </option>
                                </select>
                                
                                <div v-if="!offerEdit.sendMethod">
                                    <!-- Draft -->
                                </div>
                                <div v-else-if="offerEdit.sendMethod.alias == 'save'">
                                    <!-- Just save an active offer. updatedAt will then be updated... -->
                                </div>
                                <div v-else-if="offerEdit.sendMethod.alias == 'email'">
                                    <div class="bg-white p-8 pb-0 mb-6">
                                        
                                        <div class="flex">
                                            <label class="w-1/4" for="email">{{$t('customers.email')}}</label>
                                            <input 
                                                type="text"
                                                id="email"
                                                :value="contact.email"
                                                disabled
                                                readonly
                                                class="flex-grow cursor-not-allowed"
                                                :title="$t('offers.editCustomerToEditEmail')"
                                            />
                                        </div>
                                        
                                        <div class="flex">
                                            <label class="w-1/4" for="subject">{{$t('offers.subject')}}</label>
                                            <input 
                                                type="text"
                                                id="subject"
                                                v-model="offerEdit.send.subject"
                                            />
                                        </div>
                                        
                                        <div class="flex">
                                            <label class="w-1/4" for="body">
                                                {{$t('offers.body')}}
                                            </label>
                                            
                                            <div class="w-full relative">
                                                <div 
                                                    v-show="sendBodyMode == 'preview'"
                                                    @click.prevent="sendBodyMode = 'edit'"
                                                    class="text-left p-4 text-gray-800 border rounded mb-8 h-64 overflow-auto bg-gray-100 select-none"
                                                    :title="$t('offers.editCodeToEditBody')"
                                                    v-html="transformBodyHtml(offerEdit.send.body)">
                                                </div>
                                                
                                                <textarea 
                                                    v-show="sendBodyMode == 'edit'"
                                                    id="body"
                                                    v-model="offerEdit.send.body"
                                                    class="h-64"
                                                />
                                                
                                                <div 
                                                    v-if="sendBodyMode == 'edit'"
                                                    class="absolute bottom-0 right-0 mb-8 mr-4 flex">
                                                    
                                                    <i 
                                                        class="fas fa-undo bg-gray-100 hover:opacity-75 rounded-full w-6 h-6 ml-2 flex items-center justify-center text-sm" 
                                                        @click="offerEdit.send.body = account.send.body"
                                                        :title="$t('offers.resetToDefault')"
                                                    />
                                                    <i 
                                                        class="fas fa-check bg-cyan-dark hover:opacity-75 text-white rounded-full w-6 h-6 ml-2 flex items-center justify-center text-sm" 
                                                        @click="sendBodyMode = 'preview'"
                                                        :title="$t('offers.doneEditing')"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="offerEdit.sendMethod.alias == 'sms'">
                                    <div class="bg-white p-8 pb-0 mb-6">
                                        
                                        <div class="flex">
                                            <label class="w-1/3" for="phone">{{$t('customers.phoneNumber')}}</label>
                                            
                                            <input 
                                                type="text"
                                                id="phone"
                                                :value="contact.phone"
                                                disabled
                                                readonly
                                                class="flex-grow cursor-not-allowed"
                                                title="To edit the phone number, please edit the customer contact"
                                            />
                                        </div>
                                        
                                        <div class="flex">
                                            <label class="w-1/3" for="body">{{$t('offers.body')}}</label>
                                            
                                            <div class="w-full relative">
                                                <div 
                                                    v-show="sendBodyMode == 'preview'"
                                                    @click.prevent="sendBodyMode = 'edit'"
                                                    class="text-left p-4 text-gray-800 border rounded mb-8 h-64 overflow-auto bg-gray-100 select-none"
                                                    :title="$t('offers.editCodeToEditBody')"
                                                    v-html="transformBodyHtml(offerEdit.send.body)">
                                                </div>
                                                
                                                <textarea 
                                                    v-show="sendBodyMode == 'edit'"
                                                    id="body"
                                                    v-model="offerEdit.send.body"
                                                    class="h-64"
                                                />
                                                
                                                <div 
                                                    v-if="sendBodyMode == 'edit'"
                                                    class="absolute bottom-0 right-0 mb-8 mr-4 flex">
                                                    
                                                    <i 
                                                        class="fas fa-undo bg-gray-100 hover:opacity-75 rounded-full w-6 h-6 ml-2 flex items-center justify-center text-sm" 
                                                        @click="offerEdit.send.body = account.send.body"
                                                        :title="$t('offers.resetToDefault')"
                                                    />
                                                    <i 
                                                        class="fas fa-check bg-cyan-dark hover:opacity-75 text-white rounded-full w-6 h-6 ml-2 flex items-center justify-center text-sm" 
                                                        @click="sendBodyMode = 'preview'"
                                                        :title="$t('offers.doneEditing')"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div v-if="offerEdit.sendMethod">
                                    <div class="md:flex items-start mb-4 mt-12">
                                        <select 
                                            v-model="offerEdit.followupMethod"
                                            :class="offerEdit.followupMethod ? 'md:w-2/3' : null"
                                            class="my-0">
                                            
                                            <option 
                                                v-for="method in followupMethods"
                                                :key="method.alias"
                                                :value="method">
                                                
                                                {{method.name}}
                                            </option>
                                            
                                            <option :value="null">
                                                {{ $t('offers.followupMethods.doNotFollowUp') }}
                                            </option>
                                        </select>
                                        
                                        <select 
                                            v-if="offerEdit.followupMethod"
                                            v-model="offerEdit.followup.days"
                                            :class="offerEdit.followupMethod ? 'md:w-1/3 md:ml-4' : null"
                                            class="my-0">
                                            
                                            <template v-if="offerEdit.expireAt">
                                                <option 
                                                    v-for="days in 5"
                                                    :key="'followupMethod_'+days"
                                                    :value="days">
                                                    
                                                    {{ $tc('general.days', days) }} {{ $t('general.before').toLowerCase() }} 
                                                </option>
                                            </template>
                                            <template v-else>
                                                <option 
                                                    v-for="days in 14"
                                                    :key="'followupMethod_'+days"
                                                    :value="days">
                                                    
                                                    {{ $t('general.after') }} {{ $tc('general.days', days) }}
                                                </option>
                                            </template>
                                        </select>
                                    </div>
                                    
                                    <div 
                                        v-if="offerEdit.sendMethod && offerEdit.followupMethod"
                                        class="bg-white p-8 pb-0 mb-8">
                                        
                                        <div v-if="!offerEdit.followupMethod">
                                            
                                        </div>
                                        <div v-else-if="offerEdit.followupMethod.alias == 'email'">
                                            <div class="flex">
                                                <label class="w-1/4" for="followupEmail">Email</label>
                                                <input 
                                                    type="text"
                                                    id="followupEmail"
                                                    :value="contact.email"
                                                    disabled
                                                    readonly
                                                    class="flex-grow cursor-not-allowed"
                                                    :title="$t('offers.editCustomerToEditEmail')"
                                                />
                                            </div>
                                            
                                            <div class="flex">
                                                <label class="w-1/4" for="followupSubject">{{$t('offers.subject')}}</label>
                                                <input 
                                                    type="text"
                                                    id="followupSubject"
                                                    v-model="offerEdit.followup.subject"
                                                />
                                            </div>
                                            
                                            <div class="flex">
                                                <label class="w-1/4" for="followupBody">{{$t('offers.body')}}</label>
                                                
                                                <div class="w-full relative">
                                                    <div 
                                                        v-show="followupBodyMode == 'preview'"
                                                        @click.prevent="followupBodyMode = 'edit'"
                                                        class="text-left p-4 text-gray-800 border rounded mb-8 h-64 overflow-auto bg-gray-100 select-none "
                                                        :title="$t('offers.editCodeToEditBody')"
                                                        v-html="transformBodyHtml(offerEdit.followup.body)">
                                                    </div>
                                                    
                                                    <textarea 
                                                        v-show="followupBodyMode == 'edit'"
                                                        id="followupBody"
                                                        v-model="offerEdit.followup.body"
                                                        class="h-64"
                                                    />
                                                    
                                                    <div 
                                                        v-if="followupBodyMode == 'edit'"
                                                        class="absolute bottom-0 right-0 mb-8 mr-4 flex">
                                                        
                                                        <i 
                                                            class="fas fa-undo bg-gray-100 hover:opacity-75 rounded-full w-6 h-6 ml-2 flex items-center justify-center text-sm" 
                                                            @click="offerEdit.followup.body = account.followup.body"
                                                            :title="$t('offers.resetToDefault')"
                                                        />
                                                        <i 
                                                            class="fas fa-check bg-cyan-dark hover:opacity-75 text-white rounded-full w-6 h-6 ml-2 flex items-center justify-center text-sm" 
                                                            @click="followupBodyMode = 'preview'"
                                                            :title="$t('offers.doneEditing')"
                                                        />
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else-if="offerEdit.followupMethod.alias == 'sms'">
                                            <div class="flex">
                                                <label class="w-1/3" for="followupPhone">{{$t('customers.phoneNumber')}}</label>
                                                
                                                <input 
                                                    type="text"
                                                    id="followupPhone"
                                                    :value="contact.phone"
                                                    disabled
                                                    readonly
                                                    :title="$t('offers.editCustomerToEditPhoneNumber')"
                                                    class="flex-grow cursor-not-allowed"
                                                />
                                            </div>
                                            
                                            <div class="flex">
                                                <label class="w-1/3" for="followupBody">{{$t('offers.body')}}</label>
                                                
                                                <div class="w-full relative">
                                                    <div 
                                                        v-show="followupBodyMode == 'preview'"
                                                        @click.prevent="followupBodyMode = 'edit'"
                                                        class="text-left p-4 text-gray-800 border rounded mb-8 h-64 overflow-auto bg-gray-100 select-none "
                                                        title="To edit the sms text, please go into edit code"
                                                        v-html="transformBodyHtml(offerEdit.followup.body)">
                                                    </div>
                                                    
                                                    <textarea 
                                                        v-show="followupBodyMode == 'edit'"
                                                        id="followupBody"
                                                        v-model="offerEdit.followup.body"
                                                        class="h-64"
                                                    />
                                                    
                                                    <div 
                                                        v-if="followupBodyMode == 'edit'"
                                                        class="absolute bottom-0 right-0 mb-8 mr-4 flex">
                                                        
                                                        <i 
                                                            class="fas fa-undo bg-gray-100 hover:opacity-75 rounded-full w-6 h-6 ml-2 flex items-center justify-center text-sm" 
                                                            @click="offerEdit.followup.body = account.followup.body"
                                                            :title="$t('offers.resetToDefault')"
                                                        />
                                                        <i 
                                                            class="fas fa-check bg-cyan-dark hover:opacity-75 text-white rounded-full w-6 h-6 ml-2 flex items-center justify-center text-sm" 
                                                            @click="followupBodyMode = 'preview'"
                                                            :title="$t('offers.doneEditing')"
                                                        />
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <!-- 
                                <div v-if="!offerEdit.expireAt">
                                    <div class="md:flex items-start mb-4 mt-12">
                                        
                                        <select 
                                            v-model="offerEdit.followupMethod"
                                            :class="offerEdit.followupMethod ? 'md:w-2/3' : null"
                                            class="my-0">
                                            
                                            <option 
                                                v-for="method in followupMethods"
                                                :key="method.alias"
                                                :value="method.alias ? method : null">
                                                {{method.name}}
                                            </option>
                                        </select>
                                        
                                        <select 
                                            v-if="offerEdit.followupMethod"
                                            v-model="offerEdit.followup.days"
                                            :class="offerEdit.followupMethod ? 'md:w-1/3 md:ml-4' : null"
                                            class="my-0">
                                            
                                            <option 
                                                v-for="days in 14"
                                                :key="days"
                                                :value="days">
                                                
                                                {{ $t('general.after') }} {{ $tc('general.days', days) }}
                                            </option>
                                        </select>
                                    </div>
                                    
                                    <div 
                                        v-if="offerEdit.sendMethod && offerEdit.followupMethod"
                                        class="bg-white p-8 pb-0 mb-8">
                                        
                                        <div v-if="!offerEdit.followupMethod">
                                            
                                        </div>
                                        <div v-else-if="offerEdit.followupMethod.alias == 'email'">
                                            <div class="flex">
                                                <label class="w-1/4" for="followupEmail">Email</label>
                                                <input 
                                                    type="text"
                                                    id="followupEmail"
                                                    :value="contact.email"
                                                    disabled
                                                    readonly
                                                    class="flex-grow cursor-not-allowed"
                                                    :title="$t('offers.editCustomerToEditEmail')"
                                                />
                                            </div>
                                            
                                            <div class="flex">
                                                <label class="w-1/4" for="followupSubject">{{$t('offers.subject')}}</label>
                                                <input 
                                                    type="text"
                                                    id="followupSubject"
                                                    v-model="offerEdit.followup.subject"
                                                />
                                            </div>
                                            
                                            <div class="flex">
                                                <label class="w-1/4" for="followupBody">{{$t('offers.body')}}</label>
                                                
                                                <div class="w-full relative">
                                                    <div 
                                                        v-show="followupBodyMode == 'preview'"
                                                        @click.prevent="followupBodyMode = 'edit'"
                                                        class="text-left p-4 text-gray-800 border rounded mb-8 h-64 overflow-auto bg-gray-100 select-none "
                                                        :title="$t('offers.editCodeToEditBody')"
                                                        v-html="transformBodyHtml(offerEdit.followup.body)">
                                                    </div>
                                                    
                                                    <textarea 
                                                        v-show="followupBodyMode == 'edit'"
                                                        id="followupBody"
                                                        v-model="offerEdit.followup.body"
                                                        class="h-64"
                                                    />
                                                    
                                                    <div 
                                                        v-if="followupBodyMode == 'edit'"
                                                        class="absolute bottom-0 right-0 mb-8 mr-4 flex">
                                                        
                                                        <i 
                                                            class="fas fa-undo bg-gray-100 hover:opacity-75 rounded-full w-6 h-6 ml-2 flex items-center justify-center text-sm" 
                                                            @click="offerEdit.followup.body = account.followup.body"
                                                            :title="$t('offers.resetToDefault')"
                                                        />
                                                        <i 
                                                            class="fas fa-check bg-cyan-dark hover:opacity-75 text-white rounded-full w-6 h-6 ml-2 flex items-center justify-center text-sm" 
                                                            @click="followupBodyMode = 'preview'"
                                                            :title="$t('offers.doneEditing')"
                                                        />
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else-if="offerEdit.followupMethod.alias == 'sms'">
                                            <div class="flex">
                                                <label class="w-1/3" for="followupPhone">{{$t('customers.phoneNumber')}}</label>
                                                
                                                <input 
                                                    type="text"
                                                    id="followupPhone"
                                                    :value="contact.phone"
                                                    disabled
                                                    readonly
                                                    :title="$t('offers.editCustomerToEditPhoneNumber')"
                                                    class="flex-grow cursor-not-allowed"
                                                />
                                            </div>
                                            
                                            <div class="flex">
                                                <label class="w-1/3" for="followupBody">{{$t('offers.body')}}</label>
                                                
                                                <div class="w-full relative">
                                                    <div 
                                                        v-show="followupBodyMode == 'preview'"
                                                        @click.prevent="followupBodyMode = 'edit'"
                                                        class="text-left p-4 text-gray-800 border rounded mb-8 h-64 overflow-auto bg-gray-100 select-none "
                                                        title="To edit the sms text, please go into edit code"
                                                        v-html="transformBodyHtml(offerEdit.followup.body)">
                                                    </div>
                                                    
                                                    <textarea 
                                                        v-show="followupBodyMode == 'edit'"
                                                        id="followupBody"
                                                        v-model="offerEdit.followup.body"
                                                        class="h-64"
                                                    />
                                                    
                                                    <div 
                                                        v-if="followupBodyMode == 'edit'"
                                                        class="absolute bottom-0 right-0 mb-8 mr-4 flex">
                                                        
                                                        <i 
                                                            class="fas fa-undo bg-gray-100 hover:opacity-75 rounded-full w-6 h-6 ml-2 flex items-center justify-center text-sm" 
                                                            @click="offerEdit.followup.body = account.followup.body"
                                                            :title="$t('offers.resetToDefault')"
                                                        />
                                                        <i 
                                                            class="fas fa-check bg-cyan-dark hover:opacity-75 text-white rounded-full w-6 h-6 ml-2 flex items-center justify-center text-sm" 
                                                            @click="followupBodyMode = 'preview'"
                                                            :title="$t('offers.doneEditing')"
                                                        />
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div v-if="offerEdit.expireAt">
                                    
                                    <div class="md:flex items-start mb-4 mt-12">
                                        
                                        <select 
                                            v-model="offerEdit.expireMethod"
                                            :class="offerEdit.expireMethod ? 'md:w-2/3' : null"
                                            class="my-0">
                                            
                                            <option 
                                                v-for="method in expireMethods"
                                                :key="method.alias"
                                                :value="method.alias ? method : null">
                                                {{method.name}}
                                            </option>
                                        </select>
                                        
                                        <select 
                                            v-if="offerEdit.expireMethod"
                                            v-model="offerEdit.expire.remindDaysBefore"
                                            :class="offerEdit.expireMethod ? 'md:w-1/3 md:ml-4' : null"
                                            class="my-0">
                                            
                                            <option 
                                                v-for="days in 5"
                                                :key="days"
                                                :value="days">
                                                
                                                {{ $tc('general.days', days) }} {{ $t('general.before').toLowerCase() }} 
                                            </option>
                                        </select>
                                    </div>
                                    
                                    <div 
                                        v-if="offerEdit.sendMethod && offerEdit.expireMethod"
                                        class="bg-white p-8 pb-0 mb-8">
                                        
                                        <div v-if="!offerEdit.expireMethod">
                                            
                                        </div>
                                        <div v-else-if="offerEdit.expireMethod.alias == 'email'">
                                            <div class="flex">
                                                <label class="w-1/4" for="expireEmail">Email</label>
                                                <input 
                                                    type="text"
                                                    id="expireEmail"
                                                    :value="contact.email"
                                                    disabled
                                                    readonly
                                                    class="flex-grow cursor-not-allowed"
                                                    :title="$t('offers.editCustomerToEditEmail')"
                                                />
                                            </div>
                                            
                                            <div class="flex">
                                                <label class="w-1/4" for="expireSubject">{{$t('offers.subject')}}</label>
                                                <input 
                                                    type="text"
                                                    id="expireSubject"
                                                    v-model="offerEdit.expire.subject"
                                                />
                                            </div>
                                            
                                            <div class="flex">
                                                <label class="w-1/4" for="followupBody">{{$t('offers.body')}}</label>
                                                
                                                <div class="w-full relative">
                                                    <div 
                                                        v-show="expireBodyMode == 'preview'"
                                                        @click.prevent="expireBodyMode = 'edit'"
                                                        class="text-left p-4 text-gray-800 border rounded mb-8 h-64 overflow-auto bg-gray-100 select-none "
                                                        :title="$t('offers.editCodeToEditBody')"
                                                        v-html="transformBodyHtml(offerEdit.expire.body)">
                                                    </div>
                                                    
                                                    <textarea 
                                                        v-show="expireBodyMode == 'edit'"
                                                        id="followupBody"
                                                        v-model="offerEdit.expire.body"
                                                        class="h-64"
                                                    />
                                                    
                                                    <div 
                                                        v-if="expireBodyMode == 'edit'"
                                                        class="absolute bottom-0 right-0 mb-8 mr-4 flex">
                                                        
                                                        <i 
                                                            class="fas fa-undo bg-gray-100 hover:opacity-75 rounded-full w-6 h-6 ml-2 flex items-center justify-center text-sm" 
                                                            @click="offerEdit.expire.body = account.expire.body"
                                                            :title="$t('offers.resetToDefault')"
                                                        />
                                                        <i 
                                                            class="fas fa-check bg-cyan-dark hover:opacity-75 text-white rounded-full w-6 h-6 ml-2 flex items-center justify-center text-sm" 
                                                            @click="expireBodyMode = 'preview'"
                                                            :title="$t('offers.doneEditing')"
                                                        />
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div v-else-if="offerEdit.expireMethod.alias == 'sms'">
                                            <div class="flex">
                                                <label class="w-1/3" for="expirePhone">{{$t('customers.phoneNumber')}}</label>
                                                
                                                <input 
                                                    type="text"
                                                    id="expirePhone"
                                                    :value="contact.phone"
                                                    disabled
                                                    readonly
                                                    :title="$t('offers.editCustomerToEditPhoneNumber')"
                                                    class="flex-grow cursor-not-allowed"
                                                />
                                            </div>
                                            
                                            <div class="flex">
                                                <label class="w-1/3" for="expireBody">{{$t('offers.body')}}</label>
                                                
                                                <div class="w-full relative">
                                                    <div 
                                                        v-show="expireBodyMode == 'preview'"
                                                        @click.prevent="expireBodyMode = 'edit'"
                                                        class="text-left p-4 text-gray-800 border rounded mb-8 h-64 overflow-auto bg-gray-100 select-none "
                                                        title="To edit the sms text, please go into edit code"
                                                        v-html="transformBodyHtml(offerEdit.expire.body)">
                                                    </div>
                                                    
                                                    <textarea 
                                                        v-show="expireBodyMode == 'edit'"
                                                        id="expireBody"
                                                        v-model="offerEdit.expire.body"
                                                        class="h-64"
                                                    />
                                                    
                                                    <div 
                                                        v-if="expireBodyMode == 'edit'"
                                                        class="absolute bottom-0 right-0 mb-8 mr-4 flex">
                                                        
                                                        <i 
                                                            class="fas fa-undo bg-gray-100 hover:opacity-75 rounded-full w-6 h-6 ml-2 flex items-center justify-center text-sm" 
                                                            @click="offerEdit.expire.body = account.expire.body"
                                                            :title="$t('offers.resetToDefault')"
                                                        />
                                                        <i 
                                                            class="fas fa-check bg-cyan-dark hover:opacity-75 text-white rounded-full w-6 h-6 ml-2 flex items-center justify-center text-sm" 
                                                            @click="expireBodyMode = 'preview'"
                                                            :title="$t('offers.doneEditing')"
                                                        />
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                                -->
                                
                                <button 
                                    @click.prevent="createOffer()"
                                    :disabled="loading"
                                    type="submit"
                                    class="button submit flex-none h-full"
                                    :class="{'loading' : loading}">
                                    
                                    {{offerEdit.offerId ? this.$t('offers.updateOffer') : this.$t('offers.createOffer')}}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                
            </section>
        </div>
        <div
            v-else
            class="flex h-full w-full py-8 px-16 items-center justify-center">
            
            {{$t('offers.waitWhileFetchingCustomersAndProducts')}}
        </div>
    </div>
    <div 
        v-else
        class="w-full h-full flex  items-center justify-center">
        
        <div class="spinner"></div>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
export default {
    components: {
        'CustomerEdit': () => import('@/components/customers/CustomerEdit.vue'),
        'CustomerCard': () => import('@/components/customers/CustomerCard.vue'),
        'CustomerRow': () => import('@/components/customers/CustomerRow.vue'),
        'ProductEdit': () => import('@/components/products/ProductEdit.vue'),
        'ProductCard': () => import('@/components/products/ProductCard.vue'),
        'OfferProductCard': () => import('@/components/products/OfferProductCard.vue'),
        'Design': () => import('./sections/Design.vue'),
        DatePicker,
    },
    
    data() {
        return {
            tempId: null,
            loading: true,
            offerEdit: {
                showCustomer: true,
                heading: null,
                text: null,
                acceptButton: null,
                declineText: null,
                acceptLegal: null,
                acceptConditionsLink: null,
                acceptLegalCheck: false,
                notes: null,
                customerId: null,
                contactId: null,
                products: [],
                status: 'draft',
                designId: '1',
                sendMethod: null,
                send: {
                    body: null,
                    subject: null,
                },
                followupMethod: null,
                followup: {
                    body: null,
                    subject: null,
                    days: null,
                },
                expireAt: null,
                // expireMethod: null,
                // expire: {
                //     days: null,
                //     remindDaysBefore: null,
                //     body: null,
                //     subject: null,
                // },
                priceTotalAdjustDiscount: 0,
                priceTotalAdjustDiscountRounded: 0,
                priceTotalAdjustPrice: 0,
                show: {
                    product: {
                        priceFormated: true,
                        qty: true,
                        discount: true,
                        priceQuantityFormated: true
                    },
                    owner: true,
                    priceTotalAdjustDiscount: true,
                    vatCalculation: true,
                },
                attachments: [],
                owner: {
                    username: '',
                    firstname: '',
                    lastname: '',
                    title: '',
                    phonenumber: '',
                    email: '',
                },
            },
            sections: [
                {
                    name: this.$t('offers.sections.customer'),
                    alias: 'customer',
                },
                {
                    name: this.$t('offers.sections.products'),
                    alias: 'products',
                },
                {
                    name: this.$t('offers.sections.design'),
                    alias: 'design',
                },
                {
                    name: this.$t('offers.sections.send'),
                    alias: 'send',
                },
            ],
            designs: [
                {
                    designId: '1',
                    name: 'Simple',
                },
            ],
            showProductContextMenu: null,
            showDesignCustomize: false,
            previewWidth: 'tablet',
            snippets: {
                heading: {
                    no: [
                        'Supert tilbud', // til {{customer.fullname}}
                        'Flott tilbud som avtalt',
                    ],
                    en: [
                        'Great offer', // {{customer.fullname}}
                    ],
                },
                text: {
                    no: [
                        'Som avtalt presenteres et tilbud som forespurt. Ta gjerne kontakt direkte om du har spørsmål eller trenger mer informasjon om tilbudet eller produktene.',
                    ],
                    en: [
                        'As agreed, offers and offers are requested. Feel free to contact us directly about your questions or need more information about offers or products.',
                    ],
                },
                acceptButton: {
                    no: [
                        'Aksepter tilbud',
                        'Aksepter',
                        'Ja takk, jeg er interessert',
                        'Ja takk!',
                    ],
                    en: [
                        'Accept offer',
                        'Accept',
                    ],
                },
                declineText: {
                    no: [
                        'Avslå tilbud',
                        'Nei takk, jeg er ikke interessert',
                        'Nei takk',
                    ],
                    en: [
                        'Decline offer',
                        'Decline',
                    ],
                },
            },
            sendMethods: [
                {
                    alias: 'email',
                    name: this.$t('offers.sendMethods.sendByEmail'),
                },
                {
                    alias: 'sms',
                    name: this.$t('offers.sendMethods.sendBySms'),
                },
                {
                    alias: 'save',
                    name: this.$t('offers.sendMethods.saveAndKeepActive'),
                },
                {
                    alias: null,
                    name: this.$t('offers.sendMethods.saveAsDraftAndSendLater'),
                },
            ],
            followupMethods: [
                {
                    alias: 'email',
                    name: this.$t('offers.followupMethods.followUpAutomaticallyByEmail'),
                },
                {
                    alias: 'sms',
                    name: this.$t('offers.followupMethods.followUpAutomaticallyBySms'),
                },
                // {
                //     alias: null,
                //     name: this.$t('offers.followupMethods.doNotFollowUp'),
                // }
            ],
            // expireMethods: [
            //     {
            //         alias: 'email',
            //         name: this.$t('offers.expireMethods.remindAutomaticallyByEmail'),
            //     },
            //     {
            //         alias: 'sms',
            //         name: this.$t('offers.expireMethods.remindAutomaticallyBySms'),
            //     },
            //     {
            //         alias: null,
            //         name: this.$t('offers.expireMethods.doNotRemind'),
            //     }
            // ],
            sendBodyMode: 'preview',
            followupBodyMode: 'preview',
            // expireBodyMode: 'preview',
            activeSection: null,
            customerEdit: null,
            showProductsList: true,
            productEdit: null,
            snippetModal: null,
            offerCode: null,
        }
    },
    
    computed: {
        allProducts(){
            const products = this.$store.getters.getProducts;
            
            if (products) {
                return products.filter( p => !p.deletedAt );
            }
            else {
                return []
            }
        },
        
        allProductsWithoutOfferProducts(){
            return this.allProducts.filter( product => {
                if ( !this.offerEdit.products.find( p => p.productId == product.productId) ) {
                    return true
                }
            });
        },
        
        offerProducts(){
            return this.offerEdit.products.map( product => {
                // console.log('product: ', product);
                const productOriginal = this.allProducts.find( p => p.productId == product.productId);
                
                const vatPercent = productOriginal.vatPercent || 0;
                const priceIncludedVat = (product.price * vatPercent / 100) + product.price;
                
                const priceQuantity = (product.price - ( product.price * product.discount / 100) ) * product.qty;
                const vatQuantity = priceQuantity * vatPercent / 100;
                const priceQuantityIncludedVat = priceQuantity + vatQuantity;
                
                return {
                    ...productOriginal,
                    name: product.name,
                    description: product.description,
                    dates: product.dates || [],
                    qty: product.qty,
                    discount: product.discount,
                    price: product.price,
                    priceFormated: this.currencyFormat( product.price ),
                    priceIncludedVat,
                    priceIncludedVatFormated: this.currencyFormat( priceIncludedVat ),
                    priceQuantity,
                    vatQuantity,
                    priceQuantityFormated: this.currencyFormat( priceQuantity ),
                    priceQuantityIncludedVat,
                    priceQuantityIncludedVatFormated: this.currencyFormat( priceQuantityIncludedVat ),
                };
            });
        },
        
        allCustomers(){
            const customers = this.$store.getters.getCustomers;
            if (customers) {
                return customers.filter( c => !c.deletedAt );
            }
            else {
                return []
            }
        },
        
        customer(){
            if (
                this.allCustomers && 
                this.allCustomers.length) {
                    
                const customer = this.allCustomers.find(c => c.customerId == this.offerEdit.customerId);
                return customer;
            }
            return null
        },
        
        contact(){
            if (
                this.customer &&
                this.customer.contacts && 
                this.customer.contacts.length) {
                
                if (this.offerEdit.contactId) {
                    return this.customer.contacts.find(c => c.contactId == this.offerEdit.contactId);
                }
                else {
                    return this.customer.contacts.find(c => c.isPrimary) || this.customer.contacts[0];
                }
            }
            else {
                return null
            }
        },
        
        emailButton(){
            return `<a style="background: ${this.account.brand.colorLinks}; text-transform: uppercase; text-decoration: none; font-size: 14px; font-weight: bold; padding: .75rem 1.5rem; margin: 1rem 0; display: inline-block; border-radius: 4px; color: #fff; " href="{{offer.url}}" target="_blank" >${ this.$t('offers.seeOfferNow') }</a>`;
        },
        
        // transformBodyHtml(offerEdit.send.body)(){
        //     let response = this.offerEdit.send.body;
        //     response = response.replace(/(?:\r\n|\r|\n)/g, '<br />');
        //     response = response.replace(/{{contact.name}}/gi, this.contact.name);
        //     response = response.replace(/{{account.name}}/gi, this.account.name);
        //     response = response.replace(/{{offer.url}}/gi, this.emailButton );
        // 
        //     response = response.replace(/{{owner.firstname}}/gi, this.offerEdit.owner.firstname );
        //     response = response.replace(/{{owner.lastname}}/gi, this.offerEdit.owner.lastname );
        //     response = response.replace(/{{owner.title}}/gi, this.offerEdit.owner.title );
        //     response = response.replace(/{{owner.phonenumber}}/gi, this.offerEdit.owner.phonenumber );
        //     response = response.replace(/{{owner.email}}/gi, this.offerEdit.owner.email );
        // 
        //     // response = response.replace(/{{offer.url}}/gi, `<a style="background: ${this.account.brand.colorLinks}; text-transform: uppercase; font-size: 14px; font-weight: bold; padding: .75rem 1.5rem; margin: 1rem 0; display: inline-block; border-radius: 4px; color: #fff;" href="{{offer.url}}" target="_blank">${ this.$t('offers.seeOfferNow') }</a>`);
        // 
        //     return response;
        // },
        
        // transformBodyHtml(offerEdit.send.body)
        // transformBodyHtml(offerEdit.followup.body)
        
        priceTotalTemp(){
            const priceTotalTemp = this.offerProducts.reduce( (sum, product) => {
                return (( product.price - (product.price * product.discount / 100)) * product.qty) + sum;
            }, 0);
            
            return priceTotalTemp;
        },
        
        priceTotal(){
            return ( this.priceTotalTemp - (this.priceTotalTemp * this.offerEdit.priceTotalAdjustDiscount / 100) );
            // return this.priceTotalTemp;
        },
        
        priceTotalFormated(){
            return this.currencyFormat( this.priceTotal );
        },
        
        
        
        
        totalVatAmount(){
            // TODO calculate with global discount and total changed! 
            
            let totalVatAmount = this.offerProducts.reduce( (sum, product) => {
                const price = ( product.price - (product.price * product.discount / 100) ) * product.qty;
                const vatPercent = product.vatPercent || 0;
                const vat = price * vatPercent / 100;
                
                return vat + sum;
            }, 0);
            
            
            return totalVatAmount - (totalVatAmount * this.offerEdit.priceTotalAdjustDiscount / 100);
        },
        
        totalVatAmountFormated(){
            return this.currencyFormat( this.totalVatAmount );
        },
        
        priceTotalIncludedVat(){
            // TODO calculate with global discount and total changed! 
            
            const priceTotalTempIncludedVat = this.offerProducts.reduce( (sum, product) => {
                const price = ( product.price - (product.price * product.discount / 100) ) * product.qty;
                const vatPercent = product.vatPercent || 0;
                const vat = price * vatPercent / 100;
                
                return price + vat + sum;
            }, 0);
            
            return ( priceTotalTempIncludedVat - (priceTotalTempIncludedVat * this.offerEdit.priceTotalAdjustDiscount / 100) );
        },
        
        priceTotalIncludedVatFormated(){
            return this.currencyFormat( this.priceTotalIncludedVat );
        },
        
        getUser(){
            return this.$store.getters.getUser;
        },
        
        users(){
            return this.$store.getters.getUsers;
        },
    },
    
    watch: {
        priceTotal: {
            // Will fire as soon as the component is created
            immediate: false,
            deep: true,
            handler(priceTotalNew, priceTotalOld) {
                if (priceTotalNew == priceTotalOld) {
                    console.log('SAME: priceTotalNew, priceTotalOld', priceTotalNew, priceTotalOld);
                }
                else {
                    console.log('DIFFERENT: priceTotalNew, priceTotalOld', priceTotalNew, priceTotalOld);
                    this.offerEdit.priceTotalAdjustPrice = priceTotalNew.toFixed(2);
                }
            }
        },
    },
    
    methods: {
        setFollowUpFields(){
            this.offerEdit.followupMethod = this.followupMethods[0];
            
            if (this.offerEdit.expireAt) {
                this.offerEdit.followup.days = this.account.expire.remindDaysBefore || 2;
                this.offerEdit.followup.subject = this.account.expire.subject || '';
                this.offerEdit.followup.body = this.account.expire.body || '';
            }
            else {
                this.offerEdit.followup.days = this.account.followup.days || 3;
                this.offerEdit.followup.subject = this.account.followup.subject || '';
                this.offerEdit.followup.body = this.account.followup.body || '';
            }
        },
        
        toggleOfferExpireAt() {
            if (this.offerEdit.expireAt) {
                this.offerEdit.expireAt = null;
            }
            else {
                if (this.account.expire.days) {
                    this.offerEdit.expireAt = new Date().getTime() + ( (this.account.expire.days * 86400)*1000 );
                }
                else {
                    this.offerEdit.expireAt = new Date().getTime() + ( (2 * 86400)*1000 );
                }
            }
            
            this.setFollowUpFields();
        },
        
        priceTotalAdjustDiscountChange( event ){
            // console.log('priceTotalAdjustDiscountChange');
            this.offerEdit.priceTotalAdjustDiscount = event.target.value;
            this.offerEdit.priceTotalAdjustDiscountRounded = event.target.value;
            
            const newValue = ( this.priceTotalTemp - (this.priceTotalTemp * event.target.value / 100) );
            this.offerEdit.priceTotalAdjustPrice = newValue.toFixed(2);
        },
        
        priceTotalAdjustPriceChange( event ){
            // console.log('priceTotalAdjustPriceChange', event.target.value);
            const newValue = (( (this.priceTotalTemp - event.target.value) / this.priceTotalTemp ) * 100);
            this.offerEdit.priceTotalAdjustDiscount = newValue;
            this.offerEdit.priceTotalAdjustDiscountRounded = newValue.toFixed(1);
        },
        
        setActiveSection( section ){
            this.activeSection = section.alias;
            analytics.track( 'offer setActiveSection', section.alias);
        },
        
        addCustomer( customer ){
            this.offerEdit.customerId = customer.customerId;
            
            if (customer.isCompany) {
                this.offerEdit.show.vatCalculation = false;
            }
            else {
                this.offerEdit.show.vatCalculation = true;
            }
            
            if (customer.contacts.length) {
                const contact = customer.contacts.find(c => c.isPrimary) || customer.contacts[0];
                this.offerEdit.contactId = contact.contactId;
                analytics.track( 'offer addCustomer', contact);
            }
        },
        
        removeCustomer(){
            this.offerEdit.customerId = null;
            this.offerEdit.contactId = null;
            analytics.track( 'offer removeCustomer', {customerId: this.offerEdit.customerId} );
        },
        
        addProduct( product ){
            const addProduct = {
                productId: product.productId,
                qty: 1,
                discount: 0,
                price: product.price,
                dates: [],
                name: product.name,
                description: product.description,
            };
            
            this.offerEdit.products.push( addProduct );
            analytics.track( 'offer addProduct', product );
        },
        
        moveProduct( product, currentIndex, direction){
            this.offerEdit.products.splice( currentIndex, 1);
            
            let indexNew;
            
            if (direction == 'up') {
                indexNew = currentIndex - 1;
            }
            else if (direction == 'down') {
                indexNew = currentIndex + 1;
            }
            
            this.offerEdit.products.splice( indexNew, 0, {
                name: product.name,
                description: product.description,
                productId: product.productId,
                discount: product.discount,
                qty: product.qty, 
                price: product.price,
                dates: product.dates,
            });
            analytics.track( 'offer moveProduct', product );
        },
        
        removeProduct( product ){
            this.offerEdit.products = this.offerEdit.products.filter( p => p.productId != product.productId);
            analytics.track( 'offer removeProduct', product );
        },
        
        showSnippets( type ){
            this.snippetModal = type;
        },
        
        async createOffer(){
            try {
                if (
                    (this.offerEdit.sendMethod && this.offerEdit.sendMethod.alias == 'sms') || 
                    (this.offerEdit.follupMethod && this.offerEdit.follupMethod.alias == 'sms') ){
                    
                    if ( 
                        !this.contact.phone || 
                        this.contact.phone == '' ){
                            
                        throw Error( this.$t('offers.needPhoneNumber') );
                    }
                    else if ( 
                        this.contact && 
                        !this.contact.phone.includes('+') ){
                            
                        throw Error( this.$t('offers.needPrefix') );
                    }
                }
                
                this.loading = true;
                
                const data = {
                    ...this.offerEdit,
                    products: this.offerProducts,
                    customer: {
                        ...this.customer,
                    },
                    account: {
                        name: this.account.name,
                        brand: this.account.brand,
                        about: this.account.about,
                        video: this.account.video,
                        contact: this.account.contact,
                        senderId: this.account.senderId,
                        messages: this.account.messages,
                        feedback: this.account.feedback,
                        locale: this.account.locale,
                    },
                    endpoint: process.env.VUE_APP_APP_ENDPOINT + '/d/'+ this.offerEdit.designId +'/i.html?a='+this.accountId,
                    emailButton: this.emailButton,
                    sendMethod: this.offerEdit.sendMethod ? this.offerEdit.sendMethod.alias : null,
                    followupMethod: this.offerEdit.followupMethod ? this.offerEdit.followupMethod.alias : null,
                    contact: this.contact,
                    
                    priceTotal: this.priceTotal,
                    priceTotalFormated: this.priceTotalFormated,
                    
                    totalVatAmount: this.totalVatAmount,
                    totalVatAmountFormated: this.totalVatAmountFormated,
                    priceTotalIncludedVat: this.priceTotalIncludedVat,
                    priceTotalIncludedVatFormated: this.priceTotalIncludedVatFormated,
                };
                
                delete data.customer.notes;
                
                console.log(data);
                // return;
                
                const offer = await this.$store.dispatch('createOffer', data);
                
                if (offer.offerId) {
                    if (this.$route.params.offerId) {
                        this.$notify({ type: 'success', text: this.$t('offers.updatedOffer') }); // just in the way...
                    }
                    else {
                        this.$notify({ type: 'success', text: this.$t('offers.createdOffer') }); // just in the way...
                    }
                    
                    this.$router.push({ name: 'offers', params: {offerId: offer.offerId} });
                }
                else {
                    throw Error( this.$t('offers.problemCreating'));
                }
            } 
            catch (e) {
                this.$notify({ type: 'error', text: e.message});
                console.error(e);
            } 
            finally {
                this.loading = false;
            }
        },
        
        onAttachmentAdded( file ){
            this.offerEdit.attachments.push( file );
        },
        
        notBeforeToday(date) {
            return date < new Date(new Date().setHours(0, 0, 0, 0));
        },
        
        notBeforeTodayTwelveOClock(date) {
            return date < new Date(new Date().setHours(0, 0, 0, 0));
        },
        
        transformBodyHtml( response ){
            if (!response) {
                return
            }
            // let response = this.offerEdit.followup.body;
            response = response.replace(/(?:\r\n|\r|\n)/g, '<br />');
            response = response.replace(/{{contact.name}}/gi, this.contact.name);
            response = response.replace(/{{account.name}}/gi, this.account.name);
            response = response.replace(/{{offer.url}}/gi, this.emailButton );
            
            response = response.replace(/{{owner.firstname}}/gi, this.offerEdit.owner.firstname );
            response = response.replace(/{{owner.lastname}}/gi, this.offerEdit.owner.lastname );
            response = response.replace(/{{owner.title}}/gi, this.offerEdit.owner.title );
            response = response.replace(/{{owner.phonenumber}}/gi, this.offerEdit.owner.phonenumber );
            response = response.replace(/{{owner.email}}/gi, this.offerEdit.owner.email );
            // response = response.replace(/{{offer.url}}/gi, `<a style="background: ${this.account.brand.colorLinks}; text-transform: uppercase; font-size: 14px; font-weight: bold; padding: .75rem 1.5rem; margin: 1rem 0; display: inline-block; border-radius: 4px; color: #fff;" href="{{offer.url}}" target="_blank">${ this.$t('offers.seeOfferNow') }</a>`);
            
            return response;
        },
    },
    
    async mounted(){
        this.tempId = 'GIVVIGO:'+(Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();
        this.activeSection = this.sections[0].alias; 
        
        
        //TODO temp
        if ( process.env.NODE_ENV == 'development') { // && !this.$route.params.offerId
            // this.addCustomer( this.allCustomers[3] );
            // this.addProduct(  this.allProductsWithoutOfferProducts[0] );
            // this.addProduct(  this.allProductsWithoutOfferProducts[1] );
            // this.addProduct(  this.allProductsWithoutOfferProducts[3] );
            this.previewWidth = 'laptop';
            // this.activeSection = this.sections[1].alias; //TODO
            
         }
         //TODO temp
        
        
        this.offerEdit.heading = this.account.offer.heading || '';
        this.offerEdit.text = this.account.offer.text || '';
        this.offerEdit.acceptButton = this.account.offer.acceptButton || '';
        this.offerEdit.acceptLegal = this.account.offer.acceptLegal || '';
        this.offerEdit.declineText = this.account.offer.declineText || '';
        
        
        this.offerEdit.vatIncludingText = this.$t('offers.vatIncludingText');
        this.offerEdit.vatExcludingText = this.$t('offers.vatExcludingText');
        
        
        
        
        this.offerEdit.acceptConditionsLink = this.account.offer.acceptConditionsLink || '';
        this.offerEdit.acceptLegalCheck = this.account.offer.acceptLegalCheck || false;
        
        this.offerEdit.sendMethod = this.sendMethods[0];
        this.offerEdit.send.subject = this.account.send.subject || '';
        this.offerEdit.send.body = this.account.send.body || '';
        
        this.setFollowUpFields();
        
        
        if (this.getUser) {
            try {
                this.offerEdit.owner.username = this.getUser.username;
                
                const owner = this.users.find( user => user.Username == this.getUser.username);
                
                const email = owner.Attributes.find( a => a.Name == 'email') ? owner.Attributes.find( a => a.Name == 'email').Value : '';
                this.offerEdit.owner.email = email;
                
                if (owner && owner.info) {
                    this.offerEdit.owner.firstname = owner.info.firstname;
                    this.offerEdit.owner.lastname = owner.info.lastname;
                    this.offerEdit.owner.title = owner.info.title;
                    this.offerEdit.owner.phonenumber = owner.info.phonenumber;
                }
            } 
            catch (error) {
                console.error(error);
            }
        }
        
        
        
        
        
        if (this.$route.params.offerId) {
            try {
                await this.$store.dispatch('getOffer', this.$route.params.offerId);
            } 
            catch (e) {
                this.$notify( {type: 'error', text: 'Error when fetching fresh data from offer'});
                console.error(e);
            }
            finally {
                
                let offer = this.$store.getters.getOffers.find(offer => offer.offerId == this.$route.params.offerId);
                
                if (offer.tracking) {
                    delete offer.tracking;
                }
                
                if ( this.$route.query.duplicate ) {
                    if (offer.offerId) {
                        delete offer.offerId;
                    }
                }
                
                Object.assign(
                    this.offerEdit,
                    offer,
                    {
                        sendMethod: this.sendMethods[0],
                        followupMethod: this.followupMethods[0],
                    }
                );
                
                
                if ( this.$route.query.duplicate ) {
                    // this.offerEdit.status = 'active'; //not sure why this was set to active earlier?
                    this.offerEdit.status = 'draft';
                    
                    this.offerEdit.expireAt = null;
                    
                    delete this.offerEdit.deletedAt;
                    delete this.offerEdit.acceptedAt;
                    delete this.offerEdit.declinedAt;
                    delete this.offerEdit.followupAt;
                    delete this.offerEdit.followedupAt;
                    delete this.offerEdit.createdAt;
                    delete this.offerEdit.updatedAt;
                    
                    this.offerEdit.products.forEach( p => {
                        if (p.dates) {
                            p.dates = [];
                        }
                    });
                }
                
                this.loading = null;
            }
            
        }
        else {
            this.loading = null;
        }
        
        if (this.isMobile) {
            this.previewWidth = 'mobile';
        }
    },
}
</script>

<style lang="scss" scoped>
    label {
        // @apply mb-2;
    }
    
    .mx-datepicker {
        @apply w-full;
    
        // .mx-input-wrapper {
        //     .mx-input {
        //         @apply bg-yellow-400;
        //         @apply hidden;
        //     }
        // }
    }
</style>
